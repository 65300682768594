const Config = {
  API_URL: 'https://qa.api.mipana.com/admin',
  VERSION_NUMBER: '1.191.0',
  GW_API_REQ: '23e74fd7bc044ffc959840c831bc629c2132c84d700c5b0497c566f82727b93f',
  API_KEY_PAYMENT_LINK: '03w9yj1DcH5A4TU0bbRHduEGZjjJyGY76P8L0YYa',
  API_URL_PAYMENT_LINK: 'https://link-de-pagos.api.qa.mipana.com',
  AMPLIFY: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_blzRRfEGA',
      userPoolWebClientId: '2n9jd94e42nnsl8vcem1g490r1',
      oauth: {
        domain: 'cg-tp-back-office-qa.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://qa.backoffice.mipana.com/dashboard',
        redirectSignOut: 'https://qa.backoffice.mipana.com/login',
        responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
  },
  MAPS_API_KEY: 'AIzaSyAFmAdCVGycA8ZSGcnrUePcy9XoMl9qqNU',
};
export default Config;
