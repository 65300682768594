export const LOGIN = 'login';
export const COMMISSIONS = 'commissions';
export const FEES = 'fees';
export const TAXES = 'taxes';
export const DASHBOARD = 'dashboard';
export const AMOUNTS = 'amounts';
export const SEGMENTATION = 'segmentation';
export const REMITTANCES = 'remittances';
export const PERSON_FEATURES_FLAG = 'person-features-flag';
export const COMMERCE_FEATURES_FLAG = 'commerce-features-flag';
export const COMMERCE_WEB_FEATURES_FLAG = 'commerce-web-features-flag';

export const CARDS = 'cards';
export const CARDS_COST_LIMIT = 'card_cost_limit';
export const BANNERS = 'banners';
export const REFERRALS = 'referrals';
export const ALLIES = 'allies';
export const APPVERSION = 'appversion';
export const APPCOMMERCEVERSION = 'appcommerceversion';
export const POS_DEVICE = 'posDevice';
export const DISTRIBUTION_POS = 'distributionPos';
export const LIMIT_REPOS = 'limitRepos';
export const DISTRIBUTION_CENTER = 'distributionCenter';
export const CARDS_QUERY = 'cardsQuery';
export const PROMOTIONS = 'promotions';
export const PROMO_CATEGORIES = 'promotionsCategories';
export const TYPE_PROMOTIONS = 'typePromotions';
export const PROVIDERS_SERVICE_PAY = 'providers_service_pay';
export const PROVIDERS = 'providers';
export const CATEGORIES_SERVICE_PAYMENT = 'categories_service_payment';
export const CATEGORIES_SERVICE_RECHARGE = 'categories_service_recharge';
export const PROMOTIONS_APP_SCREEN = 'promotions_app_screen';
export const DISTRIBUTION_ZONE = 'distributionZone';
export const FEATURED = 'featured';
export const PAYMENTS_LINK = 'payments_link';
export const TAP_TO_PHONE = 'tap_to_phone';
export const ROLSCREENS = 'rol_screens';
export const ROLREGISTER = 'rol_register';
export const ROLASSIGNMENT = 'rol_assignment';
export const NATIONAL_HOLIDAYS = 'national_holidays';
export const EXPIRATION_DATE_REMITTANCE = 'expiration_date_remittance';
export const REMITTANCES_NO_USER = 'remittances_no_user';
